'use client'
import React from 'react'
import cegov from 'cegov-antd'

import { useAppAccessibility } from '@/context/accessibilityContext'

const { Accessibility } = cegov()

export const HeaderAcessibility = () => {
  const { addLengthText, removeLengthText, resetLengthText } =
    useAppAccessibility()

  return (
    <Accessibility
      childrenCookieModal={<></>}
      okCookieModal={() => {}}
      titleCookieModal={'Políticas de privacidade e termos de uso'}
      okRejectModal={() => {}}
      normalFontSize={resetLengthText}
      maximunFontSize={addLengthText}
      minimumFontSize={removeLengthText}
    />
  )
}
