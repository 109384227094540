import Image from 'next/image'
import { MailOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons'

import * as S from './styles'

import imgLogoMoradiapng from '../../../public/img/logo-entrada-moradia-ceara-negativo-05.png'
import imgLogoSCidades from '../../../public/svg/logos/scidades/logo-horizontal-negativo-scidades.svg'
import imgLogoiris from '../../../public/svg/logos/iris-logo.svg'

export function Footer() {
  return (
    <S.ContainerFooter>
      <S.MainFooter>
        <S.ContentFooter>
          <S.WrapperImg>
            <div className="img-logo">
              <Image
                alt="Logotipo do Projeto Entrada Moradia Ceará"
                src={imgLogoMoradiapng}
                sizes="100vw"
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </div>
            <div className="img-logo">
              <a
                href="https://www.cidades.ce.gov.br/"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  alt="Logotipo do Governo do Estado do Ceará"
                  src={imgLogoSCidades}
                  sizes="100vw"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }}
                />
              </a>
            </div>
            <div className="img-logo">
              <a
                href="https://irislab.ce.gov.br/"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  alt="Logotipo do Iris Laboratório de Dados e Inovação - Estado do Ceará"
                  src={imgLogoiris}
                  sizes="100vw"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain'
                  }}
                />
              </a>
            </div>
          </S.WrapperImg>

          <S.WrapperInfo>
            <div className="emphasis">
              <span>Edifício SEPLAG, 1º</span>
            </div>
            <div>
              Av. Gal Afonso Albuquerque Lima - Cambeba <br /> Fortaleza, CE -
              6082-232
            </div>
            <div className="emphasis">
              <span>cidades.ce.gov.br</span>
            </div>
          </S.WrapperInfo>

          <S.WrapperNav>
            <ul>
              <li>
                <a
                  href="https://cearatransparente.ce.gov.br/portal-da-transparencia/ouvidoria?locale=pt-BR"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="wrapper-icon-footer link">
                    <div className="icon-footer">
                      <MessageOutlined />
                    </div>
                    Ouvidoria
                  </div>
                </a>
              </li>

              <li>
                <a href="tel:+155" target="_blank" rel="noreferrer">
                  <div className="wrapper-icon-footer link">
                    <div className="icon-footer">
                      <PhoneOutlined />
                    </div>
                    Central 155
                  </div>
                </a>
              </li>

              <li>
                <a href="mailto:entrada.moradia@cidades.ce.gov.br">
                  <div className="wrapper-icon-footer link">
                    <div className="icon-footer">
                      <MailOutlined />
                    </div>
                    entrada.moradia@cidades.ce.gov.br
                  </div>
                </a>
              </li>
            </ul>
          </S.WrapperNav>
        </S.ContentFooter>
      </S.MainFooter>
      <S.BottomFooter>
        <span>
          ©2024 Governo do Estado do Ceará - Todos os direitos reservados.
        </span>
      </S.BottomFooter>
    </S.ContainerFooter>
  )
}
