'use client'

import { HeaderAcessibility } from '@/components/header/HeaderAccessibility'
import { Footer } from '@/components/Footer'

import * as S from './styles'

export function PublicLayout({ children }: { children: React.ReactNode }) {
  return (
    <S.ContainerPublicLayout>
      <HeaderAcessibility />

      <S.ContentPublicLayout>{children}</S.ContentPublicLayout>

      <Footer />
    </S.ContainerPublicLayout>
  )
}
