import styled from 'styled-components'

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;

  width: 100%;

  font-family: ${({ theme }) => theme.font.secondary};

  color: ${({ theme }) => theme.colors.white};
`

export const MainFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: ${({ theme }) => `${theme.spacings.large}`};

  background-color: ${({ theme }) => theme.colors.gray800};
`

export const BottomFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray700};

  > span {
    padding: ${({ theme }) =>
      `${theme.spacings.xsmall} ${theme.spacings.large}`};
    font-size: ${({ theme }) => theme.font.sizes.medium};
    text-align: center;
  }
`

export const ContentFooter = styled.div`
  width: 100%;
  max-width: 1140px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacings.large};

  @media screen and (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`

export const WrapperImg = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacings.small};

  > div.img-logo {
    width: 100%;
  }
`

export const WrapperInfo = styled.div`
  width: 100%;

  font-size: ${({ theme }) => theme.font.sizes.medium};
  line-height: ${({ theme }) => theme.font.sizes.xlarge};

  > div.emphasis {
    font-weight: ${({ theme }) => theme.font.bold};
  }

  > div:last-of-type {
    margin-top: ${({ theme }) => theme.spacings.xsmall};
  }

  > div:first-of-type {
    margin-bottom: ${({ theme }) => theme.spacings.xxsmall};
  }
`

export const WrapperNav = styled.div`
  width: 100%;

  font-size: ${({ theme }) => theme.font.sizes.medium};
  line-height: ${({ theme }) => theme.font.sizes.xlarge};

  > div.title {
    font-weight: ${({ theme }) => theme.font.normal};
    margin-bottom: ${({ theme }) => theme.spacings.xsmall};
  }

  > ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }

  div.wrapper-icon-footer {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    > .icon-footer {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: ${({ theme }) => theme.font.sizes.medium};

      background-color: transparent;
      width: 2.2rem;
      height: 2.2rem;
      padding: 0.2rem;
    }

    color: ${({ theme }) => theme.colors.white};

    transition: color 200ms ease-in-out;

    &.link:hover {
      color: ${({ theme }) => theme.colors.gray400};
    }
  }
`
